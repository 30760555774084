export function format_number(number) {
    return (Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')).replace('.00', '')
}
export function formatDatetime1 (datetime) {
  if (datetime==null || datetime==""){
    return null
  }
  return datetime.substring(0,4)+"/"+datetime.substring(5,7)+"/"+datetime.substring(8,10)+" "+datetime.substring(11,19)
}
export function format_order_id(order_id) {
    var format_string = ''
    order_id = order_id.toString()
    var len = order_id.toString().length
    if (len < 6) {
        for (var i=len; i<=5; i++) {
            format_string += '0'
        }
    }
    return 'PA' + format_string + order_id
}

export function format_price(price) {
    return Number(price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export function SetToDateTime(format,date,time) {
  time = time!=undefined ? time : 0
  var result = null
  var houre = 7 + time
  if (date!=null && date!='' && date!=undefined){
    date.setHours(date.getHours()+houre)

    if (format=='date'){
      result = date.toISOString().substr(0, 10)

    } else if (format=='datetime'){
      result = date.toISOString().substr(0, 10) +' '+ date.toISOString().substr(11, 8)

    } else if (format=='datetimeiso'){
      result = date.toISOString()

    } else {
      result = date.toISOString().substr(0, 10) +' '+ date.toISOString().substr(11, 8)
    }
  }
  return result
}

export function format_stock_bill_id(stock_master_id) {
    var format_string = ''
    stock_master_id = stock_master_id.toString()
    var len = stock_master_id.toString().length
    if (len < 5) {
        for (var i=len; i<=5; i++) {
            format_string += '0'
        }
    }
    return 'IE' + format_string + stock_master_id
}

export function formatMoney (num) {
  
  if (num!=null && num!='' && num!='null'){
    var money = ''
    try {
      num = num.split(".")
      
      if (num.length==1){
        money = parseInt(num.replaceAll(",","")).toLocaleString()
         
        return money
      }
      if (num.length==2){
        money = parseInt(num[0].replaceAll(",","")).toLocaleString()
        money += "."+num[1]
       
        return money
      } 
    }
    catch(err){
      return num.toLocaleString()
    }
  }
  return num
}

export function formatMoneyIsDecimal (num) {
  if (num!=null && num!='' && num!='null'){
    var money = ''
    try {
      num = num.toFixed(2)
      num = num.split(".")
      if (num.length==1){
        money = parseInt(num).toLocaleString()
        return money
      }
      if (num.length==2){
        money = parseInt(num[0]).toLocaleString()
        money += "."+num[1]
        return money
      }
    }
    catch(err){
      return num.toLocaleString()
    }
  } else {
    num = "0.00"
  }
  return num
}

export function formatDatetime (datetime) {
  if (datetime==null || datetime==""){
    return null
  }
  return datetime.substring(8,10)+"/"+datetime.substring(5,7)+"/"+datetime.substring(0,4)+" "+datetime.substring(11,19)
}

export function formatDatetime_new (datetime) {
  if (datetime==null || datetime==""){
    return null
  }
  return datetime.substring(8,10)+"-"+datetime.substring(5,7)+"-"+datetime.substring(0,4)+" "+(parseInt(datetime.substring(11,13)) + 7).toString()+datetime.substring(13,16)
}

export function formatDate_thai (datetime) {
  if (datetime==null || datetime==""){
    return null
  }
  return datetime.substring(8,10)+"/"+datetime.substring(5,7)+"/"+datetime.substring(0,4)
}

export function formatDate_eng (datetime) {
  if (datetime==null || datetime==""){
    return null
  }
  return datetime.substring(0,4)+"-"+datetime.substring(5,7)+"-"+datetime.substring(8,10)
}

export function CheckUrlPhoto (url) {
  var pic =  url.split(":")
  var type =  url.split("-")
  if (pic[0]=="blob"){
    return 1
  } else if (type.length==6){
    if (type[4]!='image'){
      return type[5]
    }
  }
  return 1
}

export function GenIE (num) {
  if (num==null){
      return ''
  }
  var text = "IE"
  var zero = 6
  var id = parseInt(num).toLocaleString();
  if (parseInt(id.length) <= zero){
    id = id.replace(",", "")
    zero -= parseInt(id.length)
    for (var i=0; i<parseInt(zero); i++){
      text += "0"
    }
    text += id
  }else{
    id = id.replaceAll(",", "")
    text += id
  }
  return text
}

export function GenPA (num) {
  if (num==null){
      return ''
  }
  var text = "PA"
  var zero = 6
  var id = parseInt(num).toLocaleString();
  if (parseInt(id.length) <= zero){
    id = id.replace(",", "")
    zero -= parseInt(id.length)
    for (var i=0; i<parseInt(zero); i++){
      text += "0"
    }
    text += id
  }else{
    id = id.replaceAll(",", "")
    text += id
  }
  return text
}

export function Trim_valueInt (v) {
  var number = ""
  for (var i of v){
    if (!isNaN(i)){
      number += i
    }
  }
  return number
}

export function Trim_value (v) {
 
  if (v!=null){
    v = v.toString().trim()
  }
  return v
}

export function Trim_rules (v) {
  var result = false
  if (v!=null){
    v = v.trim()
    if (v.length>0){
      result = true
    }
  }
  return result
}

export function isNumberWithDot (evt) {
  evt = (evt) ? evt : window.event
  var charCode = (evt.which) ? evt.which : evt.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    evt.preventDefault()
  } else {
    return true
  }
}

export function validateNotInputThai (evt) {
  var text = evt.key
  if ((text >= "a" && text <= "z") || (text >="0" && text <="9") || (text >="A" && text <="Z") || (text =="_")) {
    return true
  }

  evt.preventDefault()
}


export function isNumberWNoDot (evt) {
  evt = (evt) ? evt : window.event
  var charCode = (evt.which) ? evt.which : evt.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
    evt.preventDefault()
  } else {
    return true
  }
}

export function isNumberWitMark (evt) {
  evt = (evt) ? evt : window.event
  var charCode = (evt.which) ? evt.which : evt.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 45) {
    evt.preventDefault()
  } else {
    return true
  }
}

export function isEnglishlanguage (evt) {
  evt = (evt) ? evt : window.event
  var charCode = (evt.which) ? evt.which : evt.keyCode
  if ((charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) || charCode === 46) {
    evt.preventDefault()
  } else {
    return true
  }
}

export function GetHourList () {
  var time = []
  for (var h=0; h<24; h++) {
    h = h.toString()
    time.push({ "data": (h.length==1 ? "0" + h : h) })
  }
  return time
}

export function GetMinuteList () {
  var time = []
  for (var m=0; m<60; m++) {
    m = m.toString()
    time.push({ "data": (m.length==1 ? "0" + m : m)})
  }
  return time
}

export function DateNowNotNull () {
  var today = new Date();
  var date = today.getFullYear()+''+((today.getMonth()+1).toString().length> 1 ? (today.getMonth()+1).toString() : '0'+(today.getMonth()+1).toString())+''+(today.getDate().toString().length> 1 ? today.getDate().toString() : '0'+today.getDate().toString());
  var h = today.getHours().toString().length> 1 ? today.getHours().toString() : '0'+today.getHours().toString()
  var m = today.getMinutes().toString().length> 1 ? today.getMinutes().toString() : '0'+today.getMinutes().toString()
  var s = today.getSeconds().toString().length> 1 ? today.getSeconds().toString() : '0'+today.getSeconds().toString()
  var time = h + '' + m + '' + s
  var newDatetime = date+time;
  return newDatetime
}

export function FormatInvoice (invoiceId) {
  if(invoiceId==null)
    return ""
  var returnString=""
  var len = invoiceId.toString().length
  if(len<=6){
     for (var i = len; i < 6; i++) {
       returnString=returnString+"0"
     }
  }
  returnString=returnString+invoiceId.toString()
  return "INV"+returnString
}

export function FormatTopup (TopupId) {
  if(TopupId==null)
    return ""
  var returnString=""
  var len = TopupId.toString().length
  if(len<=5){
     for (var i = len; i < 5; i++) {
       returnString=returnString+"0"
     }
  }
  returnString=returnString+TopupId.toString()
  return  'TU'+returnString
}

export function FormatAgentTopup (TopupId) {
  if(TopupId==null)
    return ""
  var returnString=""
  var len = TopupId.toString().length
  if(len<=5){
     for (var i = len; i < 5; i++) {
       returnString=returnString+"0"
     }
  }
  returnString=returnString+TopupId.toString()
  return  'AGTU'+returnString
}

export function FormatWithdraw (WithdrawId) {
  if(WithdrawId==null)
    return ""
  var returnString=""
  var len = WithdrawId.toString().length
  if(len<=5){
     for (var i = len; i < 5; i++) {
       returnString=returnString+"0"
     }
  }
  returnString=returnString+WithdrawId.toString()
  return  'WD'+returnString
}

export function FormatCODWallet (cod_Id) {
  if(cod_Id==null)
    return ""
  var returnString=""
  var len = cod_Id.toString().length
  if(len<=7){
     for (var i = len; i < 7; i++) {
       returnString=returnString+"0"
     }
  }
  returnString=returnString+cod_Id.toString()
  return  'C'+returnString
}

export function china_order_fornat (china_order) {
  if(china_order==null)
    return ""
  var returnString=""
  var len = china_order.toString().length
  if(len<=6){
     for (var i = len; i < 6; i++) {
       returnString=returnString+"0"
     }
  }
  returnString=returnString+china_order.toString()
  return  'CHN'+returnString
}

export function set_format_date_time (val) {
  if(val != null){
    var date_tmp = val.substring(0, 10)
    const date_split = date_tmp.split('-');
    var time_tmp = val.substring(11, 19)
    var set_dattime = date_split[2]+'-'+date_split[1]+'-'+date_split[0] +' '+ time_tmp
    return set_dattime
  } else {
    return ""
  }
}
export function set_format_date_time_for_excel (val) {
  if(val != null){
    var date_tmp = val.substring(0, 10)
    const date_split = date_tmp.split('-');
    var time_tmp = val.substring(11, 19)
    var set_dattime = date_split[0]+'-'+date_split[1]+'-'+date_split[2] +' '+ time_tmp
    return set_dattime
  } else {
    return ""
  }
}

export function withdraw_return_status_text (status_id) {
  if(status_id == 0 ) {
    return 'รอตรวจสอบ'
  } else if (status_id == 1) {
    return 'กำลังดำเนินการ'
  } else if (status_id == 2) {
    return 'จ่ายแล้ว'
  } else {
    return 'ยกเลิก'
  }
}

export function format_vat_type (val) {
  if(val != null){
    if(val == 1){
      return 'No VAT'
    } else if (val == 2) {
      return 'VAT Included'
    } else if (val == 3) {
      return 'VAT Excluded'
    }
  }
}

export function getdatelongstring ( ) {
  const datenow = new Date();
  const datereturn = datenow.getFullYear().toString()+ 
      ((datenow.getMonth()+1).toString().length==1? "0" : "" )+ (datenow.getMonth()+1).toString()+
      (datenow.getDate().toString().length==1? "0" : "" )+ datenow.getDate().toString()+
      (datenow.getHours().toString().length==1? "0" : "" )+ datenow.getHours().toString()+
      (datenow.getMinutes().toString().length==1? "0" : "" )+ datenow.getMinutes().toString()+
      (datenow.getSeconds().toString().length==1? "0" : "" )+ datenow.getSeconds().toString() 
 
  return datereturn;
}



export async function SetFileBase64 (p_file) {
  var fileBase64 = null
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
  if (p_file!=null && p_file!=undefined) {
    fileBase64 = await toBase64(p_file)
  }
  return fileBase64
}

export function ChangeOrderId (pOrderId) {
  if (pOrderId==null || pOrderId==''){ return null }
  var orderId = null
  if (pOrderId!='' && pOrderId!=null){
    var pa = null
     orderId = pOrderId.toString()
     if (orderId.length>2){
       pa = orderId.substr(0,2)
       if (pa=="PA" || pa=="pa" || pa=="Pa" || pa=="pA"){
         orderId = orderId.replace(pa,'')
       }
     }
     orderId = parseInt(orderId)
  }
  return orderId
}

export function ChangeIE (pIE) {
  if (pIE==null || pIE==''){ return null }
  var importExportMasterId = null
  if (pIE!='' && pIE!=null){
    var ie = null
     importExportMasterId = pIE.toString()
     if (importExportMasterId.length>2){
       ie = importExportMasterId.substr(0,2)
       if (ie=="IE" || ie=="ie" || ie=="Ie" || ie=="iE"){
         importExportMasterId = importExportMasterId.replace(ie,'')
       }
     }
     importExportMasterId = parseInt(importExportMasterId)
  }
  return importExportMasterId
}

import { generalService_dotnet  } from '@/website/global'
import { stockService_dotnet } from '@/website/global'
import axios from 'axios'
import { header_token } from '@/website/backend/token'

export  function get_premission(codeID) {

  if(localStorage.getItem('permission_code') === null )
    {
      localStorage.clear();
      this.$router.replace("/login");
      window.location.reload();
    }else{
    var permission =   JSON.parse(localStorage.getItem('permission_code'))    
    
    var checkCode = permission.find(p => p.id == codeID  && p.authorize != null )
    
    if(checkCode != null){
      return true;
    }else{
      return false;
    }
  }
   
  
}

export  function checkAuthorize(codeID) {
  //ถ้าสามารถดูได้อย่างเดียวจะเป็น false แต่แก้ไขเป็น true
  var permission =  JSON.parse(localStorage.getItem('permission_code')) 
  
  var checkCode = permission.find(p => p.id == codeID )

  if(checkCode != null)
  {
    if(checkCode.authorize == 2){ return true}
    else{ return false}
  }else
  { 
    // console.log("ถ้าไม่มีสิทธิ์เข้าถึงหน้านี้ให้ทำยังไง");

    return false;
    
  }
}

export async function get_languages()
{
 
  var use_language;
  if(localStorage.getItem('language_data')===null){
 
    use_language = await load_languages()
  }else{
 
    use_language =  JSON.parse(localStorage.getItem('language_data'))
  }
  var set_language = localStorage.getItem("set_language");
  if(set_language===null){
    set_language= "TH"
    localStorage.setItem("set_language",set_language);
  }
 
  return  use_language[set_language];
   
}

export async function load_languages()
{
  // if(localStorage.getItem('get_date_language') ==null ){
   
   let response = await axios.post(generalService_dotnet +"General/get-language-file",{}, { headers: header_token });
   let   use_language = await JSON.parse(response.data.language).Language;
    // let get_date_language = await JSON.parse(response.data.latestUpdate);
    // console.log(".Language",use_language.Language);

    
    // let get_date_language = response.data.latestUpdate
    localStorage.setItem("language_data",    JSON.stringify(await use_language));
    // localStorage.setItem("get_date_language",    JSON.stringify(await get_date_language));
    return use_language
  // }
//  else{
//   var date = JSON.parse(localStorage.getItem('get_date_language'))
//   let response = await axios.post(generalService_dotnet +"General/get-language-file",{dateTime :date }, { headers: header_token });
//   if(response.data.language != null){
//     let get_date_language = response.data.latestUpdate
//     let   use_language = await JSON.parse(response.data.language).Language;
//     localStorage.setItem("language_data",    JSON.stringify(use_language));
//     localStorage.setItem("get_date_language",    JSON.stringify(get_date_language));
//   }
//   return  JSON.parse(localStorage.getItem('language_data'))
 
//  }
    
   
}

export function GetDate () {
  var today = new Date();
  var date = (today.getDate().toString().length> 1 ? today.getDate().toString() : '0'+today.getDate().toString())+'/'+((today.getMonth()+1).toString().length> 1 ? (today.getMonth()+1).toString() : '0'+(today.getMonth()+1).toString())+'/'+today.getFullYear();
  return date
}

export function FormatOrder (orderid) {
  if(orderid==null)
    return ""
  var returnString=""
  var len = orderid.toString().length
  if(len<=6){
     for (var i = len; i < 6; i++) {
       returnString=returnString+"0"
     }
     returnString=returnString+orderid.toString()
  } else {
    returnString=orderid.toString()
  }
  
  return 'PA'+returnString
}

export function length60 (name) {
  if (name!=null && name!=""){
    var nameLength = name.length
    if (nameLength>60){
      name = name.substring(0,60)+"..."
    }
  }
  return name
}

export async function ViewTypePage(page){

          if (localStorage.getItem('ViewType') != null) {
            var returnType  = true;
            var json_page = await JSON.parse(localStorage.getItem('ViewType'));
            var check_page = json_page.findIndex(e => e.page === page)

            if( check_page  == -1 )
            {
              return false;
            }

            json_page[check_page].type == "1"? returnType = false : returnType = true;

            return returnType;

          }
          else
          {
             return false;
          }



        }

export async function UpdateViewTypePage(page,status){

          await axios.post(stockService_dotnet+'Stock/update-view-type-shop', {

            "staffID": localStorage.getItem('staff_id'),
            "shopId": localStorage.getItem('shop_id'),
            "page": page,
            "status": status? "1" : "0"

          },{headers: header_token}).then((res) => {}).catch((error) => { });




        }
